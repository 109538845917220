import React from 'react';

import { Text, HeadText } from '../../Typography';
import Button from '../../Button';

const SmartTask = ({ onPrev, onNext }) => {
    return (
        <div className='flex flex-col items-center'>
            <div className='mb-8'>
                <img src="/tour.smarttask.gif" alt="Try a Smart Task" />
            </div>

            <HeadText fontSize='xs'>Try a Smart Task</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Adapter will make suggestions based on your preferences and schedule. 
                The more accounts you connect or memories you add, the better the suggestions.
            </Text>

            <div className='flex flex-row' style={{ width: '100%' }}>
                <Button onClick={onPrev}>Previous</Button>
                <div style={{ flex: 1 }} />
                <Button onClick={onNext}>Continue</Button>
            </div>
        </div>
    );
}

export default SmartTask;