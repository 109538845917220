import React, { ChangeEvent } from "react";
import DatePicker from 'react-datepicker';
import { Link } from "react-router-dom";

import './Input.scss';
import "react-datepicker/dist/react-datepicker.css";

export type DateInputProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

export default class DateInput extends React.Component<DateInputProps, any> {
    renderTextInput = () => {
        const {
            fieldId,
            value,
            errorText,
            error,
            onChange,
        } = this.props;

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // @ts-ignore        
        let dateValue = new Date(Date.parse(value));
        // @ts-ignore
        if (isNaN(dateValue)) {
            dateValue = new Date();
        }

        return (
            // @ts-ignore
            <DatePicker
                dateFormat="MM/dd/yyyy"
                // @ts-ignore
                selected={dateValue}
                // @ts-ignore
                onChange={(date) => onChange({
                    target: {
                        name: fieldId,
                        // @ts-ignore
                        value: date?.toLocaleDateString('en-US', options)
                    }
                })}
                id={fieldId}
                name={fieldId}
                className={errorText || error ? 'error' : ''}
            />
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}