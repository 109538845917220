import { makeObservable, observable, action, computed } from 'mobx';
import API from 'util/API';

// Enum for memory categories
export const MemoryCategory = {
  PERSONAL: 'personal',
  TRAVEL: 'travel',
  GENERAL: 'general',
  SOCIAL: 'social',
  FLIGHTS: 'flights',
  HOTELS: 'hotels',
  DINING: 'dining',
};

class UserMemoryStore {
  memories = [];
  loading = false;
  categoryFilter = null;

  constructor() {
    makeObservable(this, {
      memories: observable,
      loading: observable,
      categoryFilter: observable,
      fetchMemories: action,
      setMemories: action,
      setLoading: action,
      setCategoryFilter: action,
      filteredMemories: computed,
    });
  }

  setMemories = (memories) => {
    this.memories = memories;
  }

  setLoading = (loading) => {
    this.loading = loading;
  }

  setCategoryFilter = (category) => {
    this.categoryFilter = category;
  }

  get filteredMemories() {
    if (!this.categoryFilter) {
      return this.memories;
    }
    return this.memories.filter(memory => memory.category === this.categoryFilter);
  }

  fetchMemories = () => {
    this.setLoading(true);
    let url = '/api/prompt/memories';
    if (this.categoryFilter) {
      url += `?category=${this.categoryFilter}`;
    }

    return new Promise((resolve, reject) => {
      API.get(url)
        .then(response => response.json())
        .then(data => {
          this.setMemories(data.memories);
          this.setLoading(false);
          resolve(data);
        })
        .catch(error => {
          console.error('Error fetching memories:', error);
          this.setLoading(false);
          reject(error);
        });
    });
  }
}

const userMemoryStore = new UserMemoryStore();
export default userMemoryStore;