import React from 'react';

import { Text, HeadText } from '../../Typography';
import Button from '../../Button';

const AddMemory = ({ onPrev, onNext }) => {
    return (
        <div className='flex flex-col items-center'>
            <div className='mb-8'>
                <img src="/tour.addmemory.gif" alt="Add a Memory" />
            </div>

            <HeadText fontSize='xs'>Add a Memory</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                To provide Adapter with additional information you&apos;d like it to remember, 
                simply preface your statement with &quot;Remember.&quot; 
                Adapter will then store this information for future reference.
            </Text>

            <div className='flex flex-row' style={{ width: '100%' }}>
                <Button onClick={onPrev}>Previous</Button>
                <div style={{ flex: 1 }} />
                <Button onClick={onNext}>Continue</Button>
            </div>
        </div>
    );
}

export default AddMemory;