import React from "react";
import { inject, observer } from "mobx-react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import { HeadText } from "components/common/Typography";

import "../../brand/index.scss";
import "./index.scss";
import "./App.css";

interface AtlasPageProps {
    AtlasStore?: any;
}

class AtlasPage extends React.Component<AtlasPageProps, {}> {
    render() {
        const { AtlasStore } = this.props;

        return (
            <AuthenticatedPage>
                <BasePage>
                    <div className="adapter-fullscreen-content">
                        <div className="flex-1"></div>

                        <HeadText fontSize="xl" fontWeight="bold">Coming soon!</HeadText>

                        <div className="flex-1"></div>
                    </div>
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default inject("AtlasStore")(observer(AtlasPage));
