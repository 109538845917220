import React from 'react';
import { inject, observer } from 'mobx-react';

import './Intention.scss';
import Popover from '../Popover';

type SuggestionCategory = "flight" | "dining" | "hotel";

export type PromptSuggestion = {
    category: SuggestionCategory;
    request: string;
    short_request: string;
    refs: string[];
}

type IntentionProps = {
    title: string;
    description: string;
    Icon: React.ComponentType<{ color: string }>;
    prompt: string;
    refs?: string[];
    store?: {
        setRawPrompt: (prompt: string) => void;
    };
}

const Intention: React.FC<IntentionProps> = ({ 
    title = '', 
    description = '', 
    prompt = '', 
    Icon, 
    refs = [], 
    store 
}) => {
    const mainContent = (
        <div 
            className="adapter-intention" 
            onClick={() => store?.setRawPrompt(prompt)}
        >
            <Icon color="#73788C" />
            <div className="adapter-intention-content truncate">
                {title && <h3>{title}</h3>}
                <p>{description}</p>
            </div>
        </div>
    );

    if (!refs?.length) {
        return mainContent;
    }

    return (
        <Popover
            trigger={mainContent}
            openDelay={600}
        >
        <>
        <h2>How did we infer this?</h2>
        <p>{refs.map((ref, index) => (
              <li key={index}>{ref}</li>
            ))}</p>
        </>
        </Popover>
    );
};

export default Intention;
// export default inject('store')(observer(Intention));

export const IntentionLoading = () => (
    <div className="adapter-intention">
        <div className='icon-placeholder animated-background'></div>
        <div className="adapter-intention-content truncate">
            <p className='animated-background'>&nbsp;</p>

            <p className='animated-background'>&nbsp;</p>
        </div>
    </div>
);