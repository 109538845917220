import React from 'react';

const X = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
        <rect x="2.46191" y="2.46094" width="29.0769" height="29.0769" rx="14.5385" fill={color || "#9093A2"} />
        <path 
            d="M8.73291 8.69141L15.1486 17.2841L8.69238 24.2683H10.1462L15.797 18.1517L20.3637 24.2683H25.3078L18.5324 15.1936L24.5403 8.69141H23.089L17.884 14.3235L13.6795 8.69141H8.73291ZM10.8706 9.762H13.1426L23.1726 23.1952H20.9006L10.8706 9.762Z" 
            fill="white"
        />
    </svg>
);

export default X;