import React, { ChangeEvent, FormEvent } from "react";

import Input, { InputProps } from "../fields/Input";
import Password from "../fields/Password";
import Checkbox from "../fields/Checkbox";
import Button from "../../common/Button";
import InputWithSuggestion from "../fields/InputWithSuggestion";
import AddressInput from "../fields/AddressInput";
import DateInput from "../fields/DateInput";

import './BaseForm.scss';

export interface Config extends Omit<InputProps, 'onChange'> {
  checked?: boolean;
  selectConfig?: any;
}

interface Props {
  buttonStyle?: any;
  buttonText: string;
  config: Config[];
  isLoading: boolean;
  isDisabled?: boolean;
  onChange: (event: ChangeEvent<any>) => void;
  onSubmit: (event: FormEvent<any>) => void;
  style?: any;
  cls?: string;
}

const findFieldErrors = (config: Config[]) => {
  return config.some((c) => {
    if (c.errorText) { return true; }

    if (c.required) {
      // select boxes are a little weird
      if (c.selectConfig) {
        // cast object to boolean; if DOES NOT EXIST (false), return true indicating an error
        return !!c.selectConfig?.defaultValue === false;
      } else if (c.value === '' || c.value === undefined) {
        return true;
      }
    }

    return false;
  });
};

export default function BaseForm({
  config,
  isLoading,
  buttonText,
  buttonStyle,
  onChange,
  onSubmit,
  cls = '',
  style = {},
  isDisabled = false
}: Props) {
  const hasError = findFieldErrors(config);

  return (
    <form className={`adapter-baseform ${cls}`} onSubmit={onSubmit} style={style}>
      {config.map((input: Config) => {
        switch (input.type) {
          case 'checkbox':
            return (
              <Checkbox
                key={input.fieldId}
                {...input}
                onChange={onChange}
              />
            );

          case 'password':
            return (
              <Password
                key={input.fieldId}
                {...input}
                onChange={onChange}
              />
            );

          case 'suggestion':
            return (
              <InputWithSuggestion
                key={input.fieldId}
                {...input}
                onChange={onChange}
              />
            );

          case 'address':
            return (
              <AddressInput
                key={input.fieldId}
                {...input}
                onChange={onChange}
              />
            );

          case 'date':
              return(
                <DateInput 
                  key={input.fieldId}
                  {...input}
                  onChange={onChange}
                />
              );

          default:
            return (
              <Input
                key={input.fieldId}
                {...input}
                onChange={onChange}
              />
            );
        }
      })}

      <Button
        type="submit"
        disabled={isLoading || isDisabled || hasError}
        style={buttonStyle}
      >{buttonText}</Button>
    </form>
  );
}
