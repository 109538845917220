import React from 'react';
import { inject, observer } from 'mobx-react';

import Button from "components/common/Button";
import { GlobeIcon, TargetIcon, CompassIcon } from 'components/common/Icon';
import OnboardingWrapper from '../index';
import { Text, HeadText } from 'components/common/Typography';

type WelcomeProps = {
    OnboardingStore?: any;
}

class Welcome extends React.Component<WelcomeProps, {}> {
    render () {
        return (
            <OnboardingWrapper>
                <div className="flex-1"></div>
        
                <HeadText fontSize='2xl' fontWeight='bold' cls='adapter-font-color-primary-900'>
                    Get Started with Adapter
                </HeadText>
        
                <Text fontSize='l'>
                    Adapter collaborates with you to turn intentions into real world outcomes. 
                    The setup process is important for adding the user information and data streams that make 
                    Adapter&apos;s capabilities possible.
                </Text>
        
                <Text fontStyle='italic' fontSize='l'>
                    Getting started takes about 5-10 minutes.
                </Text>
        
                <div className="welcome-icons">
                    <div>
                        <GlobeIcon />
        
                        <Text fontSize='l' cls='adapter-font-color-gray-light-500'>Unique Insights</Text>
        
                        <Text fontWeight='light' fontSize='l' cls='adapter-font-color-gray-light-500'>
                            Self-knowledge pulled directly from your data
                        </Text>
                    </div>
        
                    <div>
                        <TargetIcon />

                        <Text fontSize='l' cls='adapter-font-color-gray-light-500'>Delegated Tasks</Text>
        
                        <Text fontWeight='light' fontSize='l' cls='adapter-font-color-gray-light-500'>
                            Time consuming tasks taken off of your plate 
                        </Text>
                    </div>
        
                    <div>
                        <CompassIcon />

                        <Text fontSize='l' cls='adapter-font-color-gray-light-500'>Intelligent Suggestions</Text>
        
                        <Text fontWeight='light' fontSize='l' cls='adapter-font-color-gray-light-500'>
                            Guidance on how to best use your time
                        </Text>
                    </div>
                </div>
        
                <Button
                    size="2xl"
                    onClick={() => { 
                        window.location.href = '/onboarding/trust';
                    }}
                >
                    Get Started &rarr;
                </Button>
        
                <div className="flex-1"></div>
            </OnboardingWrapper>
        );
    }
}

export default inject('OnboardingStore')(observer(Welcome));