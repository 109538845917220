import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import Modal from '../Modal';

import Welcome from './steps/Welcome';
import StartTask from './steps/StartTask';
import AddMemory from './steps/AddMemory';
import SmartTask from './steps/SmartTask';
import Feedback from './steps/Feedback';

import '../../../index.scss';

type TourProps = {
    UserStore?: any;
}

type TourState = {
    page: number;
}

class Tour extends React.Component<TourProps, TourState> {
    state = {
        page: 0
    }

    onPrev = () => {
        const { page } = this.state;

        this.setState({ page: page - 1 });
    }

    onNext = () => {
        const { page } = this.state;

        this.setState({ page: page + 1 });
    }

    onFinish = () => {
        const { UserStore } = this.props;

        UserStore.completeTour()
            .catch(() => {
                toast.error(
                    "Oops! Something went wrong, please try again. If you continue to see this problem, please contact customer support.", 
                    { theme: 'dark', position: 'bottom-right' }
                );
            });
    }


    renderPages = () => {
        const { page } = this.state;

        switch (page) {
            case 4:
                return <Feedback onPrev={this.onPrev} onNext={this.onFinish} />;

            case 3:
                return <SmartTask onPrev={this.onPrev} onNext={this.onNext} />;

            case 2:
                return <AddMemory onPrev={this.onPrev} onNext={this.onNext} />;

            case 1:
                return <StartTask onPrev={this.onPrev} onNext={this.onNext} />;
            
            case 0:
            default:
                return <Welcome onNext={this.onNext} />;
        }
    }

    render() {
        return (
            <Modal>
                {this.renderPages()}
            </Modal>
        );
    }
}

export default inject("UserStore")(observer(Tour));