import React from 'react';

import { Text, HeadText } from '../../Typography';
import Button from '../../Button';

const Feedback = ({ onPrev, onNext }) => {
    return (
        <div className='flex flex-col items-center'>
            <div className='mb-8'>
                <img src="/tour.feedback.png" alt="Provide feedback" />
            </div>

            <HeadText fontSize='xs'>Provide feedback</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                Not satisfied with a result? Let us know by clicking on the feedback buttons or adding a comment.
            </Text>

            <div className='flex flex-row' style={{ width: '100%' }}>
                <Button onClick={onPrev}>Previous</Button>
                <div style={{ flex: 1 }} />
                <Button onClick={onNext}>Finish</Button>
            </div>
        </div>
    );
}

export default Feedback;