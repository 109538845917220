import React from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";

import OnboardingStats from "components/stats/OnboardingStats";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import Button from "components/common/Button";
import { HeadText, Text } from "components/common/Typography";
import ImportingData from "components/stats/ImportingData";

import './oauth_redirect.scss';

interface ActivationProps {
  AuthStore?: any;
}

class OauthRedirect extends React.Component<ActivationProps, {}> {
  state = {
    showStats: false,
  }

  componentDidMount() {
    const { AuthStore } = this.props;
    const searchParams = new URL(window.location.href).searchParams
    const code = searchParams.get('code');
    const error = searchParams.get('error');

    AuthStore.oauth({ code, error })
      .then((isReconnect) => {
        // If the account we just connected was a re-connection, 
        // skip the UI stuff and redirect back to dashboard
        if (isReconnect) {
          window.location.href = '/dashboard';
          return;
        }

        this.setState({ showStats: true });
      })
      .catch((err) => toast.error(`oAuth error.`, { icon: false }));
  }

  renderOnboardingStats = () => {
    return (
      <>
        <div className="flex-1" />
        
        <HeadText fontSize="xl" fontWeight="bold">
          Importing account data...
        </HeadText>

        <Text>
          Retrieving, categorizing, and processing your data for events, insights, and preferences.
        </Text>

        <OnboardingStats />

        <Text>
            Depending on the size of your inbox, this may take a few minutes to complete. 
            In the meantime, you can continue to interact with Adapter!
        </Text>

        <Button
          size="2xl" 
          onClick={() => window.location.href = "/dashboard"}
        >Continue &rarr;</Button>
        <div className="flex-1" />
      </>
    );
  }

  render() {
    const { showStats } = this.state;

    return (
      <main className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">

            {showStats && this.renderOnboardingStats()}

            {!showStats && (
              <>
                <div className="flex-1" />

                <ImportingData />

                <div className="flex-1" />
              </>
            )}
          </div>
        </div>
      </main>
    );
  }
}

const OauthRedirectWithStores = inject("AuthStore")(observer(OauthRedirect));

const Wrapper = () => (
  <AuthenticatedPage>
    <OauthRedirectWithStores />
  </AuthenticatedPage>
)

export default Wrapper;