import React from 'react';
import { inject, observer } from 'mobx-react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { toast } from "react-toastify";

import Card from "components/common/Card";
import { HeadText, Text } from 'components/common/Typography';
import Button from 'components/common/Button';

import './OnboardingStats.scss';

type OnboardingProgressProps = {
    OnboardingStore?: any;
    buttonText: string;
    onClick: () => void;
}

class OnboardingProgress extends React.Component<OnboardingProgressProps, {}> {
    componentDidMount(): void {
        const { OnboardingStore } = this.props;
        OnboardingStore.pollForStats(() => {
            toast.success(
                "Ingestion complete!",
                { theme: 'dark', position: 'bottom-right', icon: false }
            );
        });
    }

    render() {
        const { OnboardingStore, buttonText, onClick } = this.props;

        const onboardingCompleted = OnboardingStore.onboardingCompleted;

        return (
            <Card cls='responsive-card' style={{ margin: 'auto' }}>

                <CircularProgress
                    capIsRound
                    thickness='6px'
                    size='200px'
                    max={OnboardingStore.totalEmails}
                    value={OnboardingStore.processedEmails}
                    color='#7F56D9'
                    style={{ margin: '0 auto' }}
                >
                    <CircularProgressLabel>
                        {onboardingCompleted && (
                            <HeadText fontSize='10xl'>🎉</HeadText>
                        )}

                        {!onboardingCompleted && (
                            <>
                                <HeadText>
                                    {OnboardingStore.processedEmails}
                                </HeadText>

                                <Text>Out of {` ${OnboardingStore.totalEmails}`}</Text>
                            </>
                        )}

                    </CircularProgressLabel>
                </CircularProgress>


                {onboardingCompleted && (
                    <>
                        <HeadText fontSize='s'>
                            Ingestion complete!
                        </HeadText>

                        <Text>
                            All set to start using Adapter and putting your data to work for you.
                        </Text>
                    </>
                )}

                {!onboardingCompleted && (
                    <Text>
                        Adapter is processing your account and inferring preferences. This may take a few minutes...
                    </Text>
                )}

                <Button
                    size="2xl"
                    onClick={onClick}
                    style={{ margin: '24px auto 0 auto' }}
                >{buttonText}</Button>
            </Card>
        );
    }
}

export default inject("OnboardingStore")(observer(OnboardingProgress));