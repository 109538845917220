import React from "react";
import { HeadText, Text, LINK, UL, LI } from "components/common/Typography";

export default class AccessDeniedPage extends React.Component {

  render() {
    return (
      <main className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">
            <div className="flex-1"></div>

                <HeadText fontSize="xl" fontWeight="bold">Access Denied</HeadText>

                <Text>Sorry, you don&apos;t have permission to view this page.</Text>

                <Text>If you just created an account, you must first verify your email in order to login.</Text>
                <Text>Otherwise, delete your cookies and try to login again.</Text>

                <Text>
                    Please contact <LINK href="mailto:support@adapter.com">support@adapter.com</LINK> if you continue to encounter problems.
                  </Text>

                <Text>
                  Return to the <LINK href="https://www.adapter.com">Adapter Homepage</LINK> or <LINK href="/login">Login</LINK>.
                </Text>

            <div className="flex-1"></div>
          </div>
        </div>
      </main>
    );
  }
}
