import React from 'react';

import { Text, HeadText } from '../../Typography';
import Button from '../../Button';

const StartTask = ({ onPrev, onNext }) => {
    return (
        <div className='flex flex-col items-center'>
            <div className='mb-8'>
                <img src="/tour.starttask.gif" alt="Start a task or ask a question" />
            </div>

            <HeadText fontSize='xs'>Start a task or ask a question</HeadText>

            <Text fontSize='s' fontWeight='light' cls='mt-4 mb-8 text-center'>
                You can task Adapter with booking a flight, hotel, or restaurant reservation. 
                You can also ask questions about your preferences and patterns. 
                Use the toggle to switch between these two modes.
            </Text>

            <div className='flex flex-row' style={{ width: '100%' }}>
                <Button onClick={onPrev}>Previous</Button>
                <div style={{ flex: 1 }} />
                <Button onClick={onNext}>Continue</Button>
            </div>
        </div>
    );
}

export default StartTask;