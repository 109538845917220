import React from "react";
import { HeadText, Text, LINK } from "components/common/Typography";

export default class FourOhFourPage extends React.Component {

  render() {
    return (
      <main className="adapter-basepage">
        <div className="adapter-basepage-inner">
          <div className="adapter-fullscreen-content">

            <div className="flex-1"></div>

            <HeadText fontSize="xl" fontWeight="bold">Well this is awkward...</HeadText>

            <Text>Despite our best efforts, we&apos;re not really sure how you got here.</Text>

            <Text>Maybe you'd like to return to the <LINK href="/dashboard">Dashboard</LINK>?</Text>

            <div className="flex-1"></div>
          </div>
        </div>
      </main>
    );
  }
}
