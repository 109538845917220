import React from 'react';

import Card from 'components/common/Card';
import { Text, HeadText } from 'components/common/Typography';
import Loading from 'components/common/Loading';

const ImportingData = () => {
    return (
        <Card style={{ alignItems: 'center' }}>
            <Loading modal={false} />

            <HeadText cls='adapter-font-color-primary-900' fontWeight='bold' fontStyle='italic' fontSize='xs'>
                Importing account data...
            </HeadText>

            <Text>
                Retrieving, categorizing, and processing your data for events, insights, and preferences.  
            </Text>
        </Card>
    );
};

export default ImportingData;