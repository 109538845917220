import { usePostHog } from "posthog-js/react";

export default function withPostHog(Component: React.ComponentType<any>) {
  function ComponentWithPostHogProp(props: any) {
    const posthog = usePostHog();

    return <Component {...props} userEvents={{ posthog }} />;
  }

  return ComponentWithPostHogProp;
}
