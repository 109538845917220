import React from 'react';

const Spinner = () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z" 
            fill="url(#paint0_linear_6047_4414)"
        />
        <path 
            d="M0.45 18C0.45 8.3074 8.3074 0.45 18 0.45C27.6926 0.45 35.55 8.3074 35.55 18C35.55 27.6926 27.6926 35.55 18 35.55C8.3074 35.55 0.45 27.6926 0.45 18Z" 
            stroke="black" 
            strokeOpacity="0.08" 
            strokeWidth="0.9" 
        />
        <path
            d="M18.0073 18.8189C22.9167 18.8189 26.7707 25.3578 26.7707 25.3578L27.0079 25.2117L17.9869 9L9.00789 25.2117L9.24268 25.3578C9.24268 25.3578 13.0978 18.8189 18.0073 18.8189Z" 
            fill="#FEFDF9" 
        />
        <defs>
            <linearGradient id="paint0_linear_6047_4414" x1="36" y1="36" x2="36" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FBC8D4" />
                <stop offset="1" stopColor="#9795F0" />
            </linearGradient>
        </defs>
    </svg>

);

export default Spinner;