import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Button from 'components/common/Button';
import NLComprehension from 'components/common/NLComprehension';
import { usePostHog } from 'posthog-js/react';

const QueryOption = ({ text, NLPStore }: { text: string, NLPStore?: any }) => {
    const [ submittedWrong, setSubmittedWrong ] = useState(false);
    const [ submittedParsing, setSubmittedParsing ] = useState(false);

    const posthog = usePostHog()

    const wrongResultsHandler = () => {
        NLPStore.giveFeedback("UserFeedbackWrongResults");
        posthog.capture("query feedback", {feedback: 'wrong results'})
    }

    const incorrectParsingHandler = () => {
        NLPStore.giveFeedback("UserFeedbackIncorrectQueryParsing");
        posthog.capture("query feedback", {feedback: 'incorrect parsing'})
    }

    return (
        <>
            <NLComprehension />

            {text}

            <div className="nl-feedback">
                <Button
                    disabled={submittedWrong}
                    onClick={wrongResultsHandler}
                >Wrong Results</Button>

                <Button
                    disabled={submittedParsing}
                    onClick={incorrectParsingHandler}
                >Incorrect query parsing</Button>
            </div>
        </>
    );
};

export default inject("NLPStore")(observer(QueryOption));