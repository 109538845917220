import React from "react";
import { inject, observer } from "mobx-react";
import { AiOutlineApi } from "react-icons/ai";

import Button from "components/common/Button";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from "components/common/BasePage";
import Card from "components/common/Card";
import HeaderWithButton from "components/common/HeaderWithButton";
import Tabs from "components/common/Tab";

import ConnectAccountPopup from "./ConnectAccountPopup";
import AccountDisplay from './AccountDisplay';
import AccountStats from "./AccountStats";
import { HeadText, Text } from "components/common/Typography";

import "../../brand/index.scss";
import "./index.scss";

interface ConnectedAccountsPageProps {
    UserStore?: any;
}

export interface Account {
    accountVendor: string;
    accountEmail: string;
    connected: boolean;
    userId: string;
    configuration: {
        labels: string[];
        timeRange: {
            unit: 'months' | 'years';
            value: number;
        }
    };
}

class ConnectedAccountsPage extends React.Component<ConnectedAccountsPageProps, any> {
    state = {
        isModalOpen: false
    };

    onConnectButtonClick = () => {
        this.setState({ isModalOpen: true });
    }

    onCloseModal = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        const { isModalOpen } = this.state;
        const { UserStore } = this.props;

        const tabs = [
            { title: 'Profile', url: '/settings/profile' },
            { title: 'Details', url: '/settings/user-knowledge' },
            { title: 'Password', url: '/settings/password' },
            { title: 'Accounts', url: '/settings/accounts', active: true },
        ];
        return (
            <AuthenticatedPage>
                <BasePage>
                    <div className="adapter-fullscreen-page">
                        <Tabs tabs={tabs} />

                        <HeaderWithButton
                            content={
                                <>
                                    <HeadText fontSize="xs" cls='adapter-font-color-primary-900' fontWeight='bold'>
                                        Connected accounts
                                    </HeadText>

                                    <Text>Revoke access to an account or connect a new account.</Text>
                                </>
                            }
                            button={
                                <Button onClick={this.onConnectButtonClick}>
                                    <AiOutlineApi style={{ display: 'inline-block' }} /> Connect more accounts
                                </Button>
                            }
                        />

                        <div className="adapter-connected-accounts">
                            {UserStore.userAccounts?.map((account: Account, index: number) => (
                                <Card key={index}>
                                    <AccountDisplay account={account} buttonText="Save changes" />
                                    <AccountStats accountEmail={account.accountEmail} />
                                </Card>
                            ))}
                        </div>

                        <div className="flex-1"></div>

                        {isModalOpen && <ConnectAccountPopup onClose={this.onCloseModal} />}
                    </div>
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default inject("UserStore")(observer(ConnectedAccountsPage));
