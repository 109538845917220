import React, { useState } from 'react';
import Button from "components/common/Button";
import StepHeader from './StepHeader';
import OnboardingWrapper from '../index';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import { Text, HeadText, UL, LI, LINK } from 'components/common/Typography';

const Trust = () => {
    const [hasAccepted, setHasAccepted] = useState(false);
    const [showModal, setShowModal] = useState(false);

    return (
        <OnboardingWrapper>
            {showModal && (<PrivacyPolicyModal onClose={() => setShowModal(false)} />)}

            <StepHeader currentStep={1} />

            <div className="adapter-two-col">

                <div className="adapter-two-col--title">
                    <div className="flex-1"></div>

                    <HeadText fontSize='xl' cls='adapter-font-color-primary-900' fontWeight='bold'>
                        Adapter&apos;s Commitment to Trust and Transparency
                    </HeadText>

                    <div className="flex-1"></div>
                </div>

                <div className="adapter-two-col--content">
                    <div className="flex-1"></div>

                    <Text>
                        At Adapter, our mission is to empower you. To do so, we need personal information from you.&nbsp;
                        We don&apos;t take this responsibility lightly. Earned trust, privacy, and transparency are pillars of the Adapter experience.
                    </Text>

                    <Text>
                        We aim to lead with an individual-centric philosophy that emphasizes:
                    </Text>

                    <UL>
                        <LI><Text>Robust privacy measures</Text></LI>
                        <LI><Text>Clear consent processes</Text></LI>
                        <LI><Text>Open communication about how your information is collected, used, disclosed and stored</Text></LI>
                    </UL>


                    <div className='flex flex-row gap-4 items-center'>
                        <input
                            type="checkbox"
                            className=''
                            onChange={v => setHasAccepted(v.target.checked)}
                            style={{ cursor: 'pointer' }}
                            aria-label="I agree to Adapter's Privacy Policy"
                        />
                        
                        <Text cls='flex-1'>
                            I agree to Adapter&apos;s <LINK onClick={() => setShowModal(true)}>Privacy Policy</LINK>
                        </Text>
                    </div>

                    <Button
                        onClick={() => { window.location.href = '/onboarding/details'; }}
                        size="2xl"
                        disabled={!hasAccepted}
                        style={{ alignSelf: 'end' }}
                    >
                        Continue &rarr;
                    </Button>

                    <div className="flex-1"></div>
                </div>
            </div>
        </OnboardingWrapper>
    );
}

export default Trust;