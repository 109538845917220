import React from 'react';
import { inject, observer } from 'mobx-react';

import BasePage from 'components/common/BasePage';
import PromptFlow from './prompt/PromptFlow';
import Tour from 'components/common/Tour';

import './GettingStarted.scss';

type GettingStartedProps = {
    UserStore?: any;
}

const GettingStarted = ({ UserStore }: GettingStartedProps) => {
    if (UserStore.isOnboarding) {
        return <></>;
    }

    return (
        <BasePage>
            <PromptFlow />
            {!UserStore.hasTakenTour && <Tour />}
        </BasePage>
    );
}

export default inject("UserStore")(observer(GettingStarted));